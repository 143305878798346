import { createSlice } from '@reduxjs/toolkit';
import getThreatActors from '../../../services/threatActorsApi';
import getMitigationsDetails from '../../../services/mitigationsDetails';
import getUserDataApi from '../../../services/userDataApi';
import updateMitigation from '../../../services/updateMitigation';
import getClientsSettings from '../../../services/settings/getClientsSettings';
import getClientDetailsSettings from '../../../services/settings/getClientDetailsSettings';
import getClientDetailsAssets from '../../../services/settings/getClientDetailsAssets';
import getClientDetailsWatches from '../../../services/settings/getClientDetailsWatches';

const initialState = {
  client_id: '',
  threatActors: [],
  calendarDates: [],
  isLoading: false,
  totalPages: [],
  mitigationDetails: [],
  filters: '',
  clients: [],
  settingsClients: [],
  settingsClientDetails: {},
  settingsClientAssets: {},
  settingsClientWatches: {},
  severitys: [],
  statusList: [],
  userInfo: '',
};

export const rdSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    getThreatActorsTable: (state, action) => {
      state.threatActors = action.payload;
    },
    getCalendarDates: (state, action) => {
      state.calendarDates = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    getMitigationDetails: (state, action) => {
      state.mitigationDetails = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setSeveritys: (state, action) => {
      const severitysArray = action.payload.map((item) => ({
        label: item.charAt(0).toUpperCase() + item.slice(1),
        value: item,
      }));
      state.severitys = severitysArray;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setStatusList: (state, action) => {
      const statusArray = action.payload.map((item) => ({
        label: item,
        value: item,
      }));
      state.statusList = statusArray;
    },
    setSettingClients: (state, action) => {
      state.settingsClients = action.payload;
    },
    setSettingClientDetails: (state, action) => {
      state.settingsClientDetails = action.payload;
    },
    setSettingClientAssets: (state, action) => {
      state.settingsClientAssets = action.payload;
    },
    setSettingClientWatches: (state, action) => {
      state.settingsClientWatches = action.payload;
    },
    setClientId: (state, action) => {
      state.client_id = action.payload;
    },
  },
});

export const {
  getThreatActorsTable,
  getCalendarDates,
  setIsLoading,
  getTotalPages,
  getMitigationDetails,
  setFilters,
  setClients,
  setSeveritys,
  setUserInfo,
  setStatusList,
  setSettingClients,
  setSettingClientDetails,
  setSettingClientAssets,
  setSettingClientWatches,
  setClientId,
} = rdSlice.actions;

export const fetchUserData = (navigate) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const responseUserInfo = await getUserDataApi(navigate);
    dispatch(setUserInfo(responseUserInfo.admin_name));
    dispatch(setClients(responseUserInfo.clients_info));
    dispatch(setSeveritys(responseUserInfo.severity_list));
    dispatch(setStatusList(responseUserInfo.status_list));
    dispatch(setIsLoading(false));
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchMitigationsData =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { params } = options;
      const responseMitigations = await getThreatActors(params);
      dispatch(getThreatActorsTable(responseMitigations.mitigation_list));
      dispatch(
        getCalendarDates({
          start_date: responseMitigations.start_date,
          end_date: responseMitigations.end_date,
        }),
      );
      dispatch(getTotalPages(responseMitigations.total_pages));
      dispatch(setIsLoading(false));
    } catch (err) {
      throw new Error(err);
    }
  };

export const fetchMitigationsDetails = (id) => async (dispatch) => {
  try {
    const [responseMitigationsDetails, responseUserInfo] = await Promise.all([
      getMitigationsDetails(id),
      getUserDataApi(),
    ]);
    dispatch(setUserInfo(responseUserInfo.admin_name));
    dispatch(getMitigationDetails(responseMitigationsDetails));
  } catch (err) {
    throw new Error(err);
  }
};

export const updateMitigationDetail =
  (options = {}) =>
  async () => {
    try {
      const { params } = options;
      const responseMitigationsDetails = await updateMitigation(params);
      console.log(responseMitigationsDetails);
    } catch (err) {
      throw new Error(err);
    }
  };

export const getSettings = () => async (dispatch) => {
  try {
    const responseClients = await getClientsSettings();
    dispatch(setSettingClients(responseClients));
  } catch (err) {
    throw new Error(err);
  }
};

export const getSettingsClientDetails =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { params } = options;
      const responseClientDetails = await getClientDetailsSettings(params);
      const responseClientAssets = await getClientDetailsAssets(params);
      const responseClientWatches = await getClientDetailsWatches(params);
      dispatch(setClientId(params.client_id));
      dispatch(setSettingClientDetails(responseClientDetails));
      dispatch(setSettingClientAssets(responseClientAssets));
      dispatch(setSettingClientWatches(responseClientWatches));
      dispatch(setIsLoading(false));
    } catch (err) {
      throw new Error(err);
    }
  };

export default rdSlice.reducer;
